<template>

  <div class="row main mx-4">
    <div class="col-md-12 d-flex ">
      <div class="welcome-message">
        <h2>Hello {{ parentName }}, we're glad you're here!</h2>
        <p>Our parent portal is designed to keep you informed and involved in your child's educational journey. Explore our resources and stay connected!</p>
      </div>
    </div>
  </div>
  <div class="row main mx-4 mb-3">

      <div class="col-md-3 col-sm-3 ">
        <div class="row d-flex">
          <div class="col-md-12 ">
            <div class="row ">
              <div class="col-md-12 mb-3  d-flex justify-content-center align-content-center ">
                <img class="parentPic bg-white " src="../../assets/images/user.png" alt="parent image">
              </div>
              <div class="col-md-12 mb-3 ">
                <h3 class="text-center p-0 m-0">Jane Doe</h3>
                <p class="muted-text text-center p-0 m-0">marufutinotenda28@gmail.com</p>
                <p class="muted-text text-center p-0 m-0">+263 78 421 8739</p>
              </div>
            </div>
          </div>
          <div class="col-md-12 py-3 ">
            <div class="row">
              <div class="col-md-5 col-sm-6 display-4  ">
                <router-link class="links" to="#">
                  <i class="fa fa-umbrella text-center mt-2"></i>
                  <p class="link text-center mt-2">Profile</p>
                </router-link>

              </div>
              <div class="col-md-5 col-sm-6 display-4  ">
                <router-link class="links" to="Results">
                  <i class="fa fa-umbrella text-center mt-2 "></i>
                  <p class="link text-center mt-2">Academics</p>
                </router-link>

              </div>
            </div>
            <div class="row">
              <div class="col-md-5 display-4 col-sm-6   ">
                <router-link class="links" to="/acceptpayment">
                  <i class="fa fa-umbrella text-center mt-2"></i>
                  <p class="link text-center mt-2" >fees </p>
                </router-link>

              </div>
              <div class="col-md-5 display-4 col-sm-6 ">
                <router-link class="links" to="#">
                  <i class="fa fa-umbrella text-center mt-2"></i>
                  <p class="link text-center mt-2">Other</p>
                </router-link>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9 col-sm-9 right justify-content-center align-content-center">
        <div class="row">
          <div class="col-md-12 ">
            <div class="row ">
              <div class="col-md-4  ">
                <div class="row ">
                  <div class="col-md-12 box m-2 ">
                    <h3 class="text-center">300 Zig</h3>
                    <p class="text-center">Total fee outstanding</p>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-8 ">
                    <p class="justify-content-center align-content-center mt-4">We are here to help and answer any questions you <br>
                      might have. We look forward to hearing from you.
                    </p>
                  </div>
                  <div class="col-md-2 float-md-start">
                    <img src="../../assets/images/icon-1.png" alt="">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <router-link to="/contactus" class="btn btn-success btn-sm">Contact Us</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 ">
            <table class="table table-bordered table-secondary">
              <thead>
              <tr>
                <th>Student Name</th>
                <th>Form</th>
                <th>Fees Balance</th>

              </tr>
              </thead>
              <tbody>
              <tr v-for="row in tableData" :key="row[0]">
                <td>{{ row[0] }}</td>
                <td>{{ row[1] }}</td>
                <td>{{ row[2] }}</td>

              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>


  </div>


</template>

<script>
import ParentsNav from "@/components/ParentsNav.vue";

export default {
  components: {ParentsNav},
  data() {
    return {
      tableData: [
        ["Science", "", "", "", ""],
        ["Mathematics", "50", "D", "CE", "66"],
        ["Combined Science", "45", "E", "UE", "45"],

      ],
      parentName: 'John Doe',
      studentName: 'Jane Doe',
      studentId: '123456',
      classTeacher: 'Ms. Smith',
      schoolName: 'Springfield High School',
      gradeLevel: '9th',
      averageGrade: 'B+',
      outstandingBalance: 500,
      attendance: '95%',
      absences: 3,
      tardiness: 2,
      latestSchoolAnnouncement: 'Back to School Night - Aug 25th',
      latestTeacherMessage: 'Welcome to the new semester!',
      upcomingEvent1: 'School Play - Sep 1st',
      upcomingEvent2: 'Parent-Teacher Conference - Sep 15th',
      upcomingEvent3: 'School Festival - Oct 1st',
      recentGrade1: 'Math - A-',
      recentGrade2: 'English - B+',
      recentGrade3: 'Science - B',
    };
  },
};
</script>

<style scoped>


.welcome-message h2 {
  font-weight: bold;

}

.welcome-message p {
  font-size: 16px;

}

.main{
  background-color: #090051;
  border:1px solid white;
  color: #FFffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}

.box {
  width: 350px;
  height: 138px;
  background: silver;
  transition: width 0.5s ease-in-out;
  padding: 60px 10px 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.links {
  display: flex;
  flex-direction: column;
   margin: 5px;
  background: silver;
  transition: width 0.5s ease-in-out;
  justify-content: center;
  align-content: center;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  color: #FFffff;
}
.links:hover {

   margin: 2px;

}


h2 {
  font-weight: bold;

}

p {
  font-size: 16px;

}
.parentPic{
  border-radius: 50%;
}
.link{
  text-transform: capitalize;
  font-size:16px;
  font-weight: bold;

}

.btn {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
}

</style>