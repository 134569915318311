<template>
<div class="container-fluid">
  <BannerSlider/>
  <EventsCalender/>
  <div class="row ">
    <div class="col-md-12  ">
      <h1 class="he">OUR CURRICULUM</h1>
      <div class="row d-flex justify-content-center align-content-center">
        <div class="col-md-6">
          <div class="popular-course">
            <p class="">
              Our diverse curriculum offers a wide range of subjects, catering to various academic interests,
              including sciences, technical subjects, technology, arts, and commerce. Our curriculum is designed to
              foster critical thinking and provide a comprehensive understanding of each field, empowering you to
              pursue your passions and academic goals.
            </p>
            <p class="">
              At Southmews, we provide comprehensive preparation for
              <span class="exam-boards">ZIMSEC</span> and
              <span class="exam-boards">Cambridge International Examinations</span>, ensuring students are confident
              and prepared for both local and international assessments. Their dedicated instructors guide students
              through comprehensive exam techniques.
            </p>
          </div>
        </div>
        <div class="col-md-6 img">
          <img src="../assets/images/student-exam.jpg" alt=""  width="100%" height="90%">
        </div>

      </div>

    </div>

    <div class="col-md-12">
      <div class="row d-flex justify-content-center align-content-center">
        <div class="col-md-4">
          <div class="subject d-flex flex-column">
            <h1 class="he">Fields of Study</h1>
            <div class="subject-box ">
              <h2>Subjects And Courses</h2>
              <i class="fa fa-book"></i>
              <h3 class="sb-he">Commercials, Arts, Sciences</h3>
              <div class="subject-description">

                <p>Our school offers a diverse range of subjects spanning the humanities, sciences, arts, languages,
                  and physical education. This well-rounded curriculum is designed to provide students with a
                  comprehensive educational experience and prepare them for success in their future academic and
                  professional pursuits.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="subject d-flex flex-column">
            <h3>Non-Academics</h3>
            <div class="subject-box">
              <h2> Extracurricular Activities</h2>
              <i class="fa fa-soccer-ball-o"></i>
              <h3 class="sb-he">Engage in various activities beyond academics.</h3>

              <div class="subject-description">

                <p>These extracurricular activities provide students with opportunities to explore their interests,
                  develop new skills, build leadership experience, and engage with the broader school and local
                  community. The diverse range of offerings caters to students with varying passions and talents,
                  encouraging them to pursue well-rounded personal growth and enrichment beyond the classroom.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 ">
          <div class="subject d-flex flex-column">
            <h3>Examination Board</h3>
            <div class="subject-box">
              <h2> Exam Options</h2>
              <i class="fa fa-check-circle"></i>
              <h3 class="sb-he">Choose your examination pathway.</h3>
              <div class="subject-description">
                <p>Our school offers exams from both ZIMSEC and Cambridge International Examinations. This dual option
                  allows students to choose the pathway that suits their academic goals and future aspirations.</p>

                <p>ZIMSEC caters to domestic education, while Cambridge provides globally recognized credentials. This
                  flexible framework supports diverse student needs and aspirations.</p>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="d-flex justify-content-center mt-3">
        <div class="links">
          <router-link class="links" to="/SubjectsAndCourses">
            <i class="fa fa-arrow-right"></i> View All Courses
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <div class=" mt-3">
    <div class="row ">
      <div class="col-md-6">
        <div class="our-teacher">
          <div class="row">
            <div class="col-md-12">
              <h3 class="he">MEET OUR TEACHERS</h3>
              <p>
                Discover our outstanding faculty, comprised of knowledgeable
                experts who are committed to fostering a love for learning and
                empowering students to reach their full potential. Experience the
                transformative impact of our dedicated teachers and join our
                educational community today.
              </p>
              <p>
                In addition to our exceptional faculty, we offer a range of
                specialized instructors and industry professionals who bring
                real-world expertise to the classroom. Expand your horizons with
                our extended network of educators and enhance your learning
                experience with practical insights and valuable connections.
              </p>
            </div>
            <div class="col-md-12">
              <div class="row  d-flex link justify-content-center align-content-center">
                <div class="col-md-4 col-5 links">
                  <router-link class=" links" to="/careers"> Career with us</router-link>
                </div>
                <div class="col-md-4 col-5 links">
                  <router-link class=" links" to="/Teachers"> Our Teachers</router-link>
                </div>
              </div>
            </div>
          </div>



        </div>
      </div>
      <div class="col-md-6 ">
        <div class="our-teacher">
          <div class="row ">
            <div class="col-md-6 " v-for="teacher in teachersRand" :key="teacher.teacherId">
              <div class="card-wrapper">
                <div class="card">
                  <img src="../assets/images/background.jpg" alt="card background" class="card-img">
                  <img :src="teacher.image" alt="profile images" class="profile-img">
                  <h1>
                    <span v-if="teacher.gender.toLowerCase()==='male'">Mr </span>
                    <span v-else>Mrs</span>
                    {{ "  " + teacher.lastName }}
                  </h1>
                  <p class="job-title">{{ teacher.designation }}</p>
                </div>

              </div>
            </div>
          </div>
        </div>



      </div>
    </div>
  </div>


</div>




</template>
<script>


import BannerSlider from "@/components/BannerSlider.vue";
import EventsCalender from "@/components/EventsCalender.vue";
import {services} from "@/services/axios";
import Imageslide from "@/components/imageslide.vue";


export default {
  components: {
    Imageslide,
    EventsCalender,
    BannerSlider,


  },
  data() {
    return {
      teachers: [
        {
          teacherId: 1,
          lastName: "Svuure",
          gender: "Female",
          image: require('@/assets/images/WhatsApp Image 2024-10-09 at 11.48.51.jpeg'),
          designation: "Grade 3 Teacher",

        },
        {
          teacherId: 2,

          lastName: "Chikanda",
          gender: "Female",
          image: require('@/assets/images/WhatsApp Image 2024-10-09 at 11.49.16.jpeg'),
          designation: "ECD A  and TIC Teacher",

        },
        {
          teacherId: 3,
          lastName: "Mharidzo",
          gender: "Female",
          image: require('@/assets/images/WhatsApp Image 2024-10-09 at 11.49.44.jpeg'),
          designation: "ECD B Teacher",

        },
        {
          teacherId: 4,
          lastName: "Gwerengwengwe",
          gender: "Female",
          image: require('@/assets/images/WhatsApp Image 2024-10-09 at 11.50.09.jpeg'),
          designation: "Grade 1 Teacher Teacher",

        },
      ],
      teachersRand: [],

      loading: false,
      error: "",


      items: [
        {
          id: 1,
          text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
        {
          id: 2,
          text: 'Desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum',
        },
        {
          id: 3,
          text: 'Many desktop publishing packages and web page editors now use',
        },
        {
          id: 4,
          text: 'Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).',
        },
      ],

    }

  },
  async created() {
    // try {
    //   this.loading=true
    //   let response=await services.getAllTeachers()
    //
    //   this.teachers=response.data
    //   console.log(teachers)
    //   this.loading=false
    // }
    // catch (e) {
    //   this.loading=true
    //
    //   this.error=e
    //   this.loading=false
    // }
    this.randomTeachers()

  },
  methods: {
    randomTeachers() {
      const count = this.teachers.length;

      const randomTeachers = [...this.teachers];


      const maxRandomTeachers = Math.min(4, count);


      for (let i = 0; i < maxRandomTeachers; i++) {
        const random = Math.floor(Math.random() * randomTeachers.length);
        const randomTeacher = randomTeachers.splice(random, 1)[0];
        this.teachersRand.push(randomTeacher);
      }


    },

  }
}
</script>
<style scoped>
/********** Slider CSS Starts **********/
.subject{

  width: 25rem;
  height: 20rem;
}
.subject-box {
  width: 25rem;
  height: 20rem;
  background:  #2c3e50;
  border-bottom: 3px solid goldenrod;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  transition: width 0.5s ease-in-out;
  justify-content: center;
  align-content: center;


}

.subject-box:hover {
  background: #2c3e50;
  border-bottom: 3px solid goldenrod;
}

.subject-box h2 {
  font-size: 28px;
  color: white; /* White text for headings */
}

.subject-box p {
  font-size: 14px;
  color: white; /* White text for paragraphs */
}

.subject-box .subject-description {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  color: white;
  font-size: 1.5rem;
  padding: 20px;
  line-height: 1.5px;
  box-sizing: border-box;
  transition: top 0.5s ease-in-out;
}

.subject-box:hover .subject-description {
  top: 0;
}

.subject-box i {
  margin-top: 3px;
  font-size: 34px;
  color: gold;
}

/********** Slider CSS Starts **********/


.main-section p {
  text-align: justify;
  font-weight: 400;
  margin-top: 10px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0;
}

.popups h5 {
  color: #2B6E60;
  font-weight: bold;
  font-size: 18px;
  margin-top: 24px;
}



.circle img {
  background: #4caf50;
  height: 100px;
  width: 100px;
  border-radius: 50px;
  padding: 12px;
  color: gold;
}

a {
  text-decoration: none;
}


.flip-container:hover .flipper {
  transform: rotateY(180deg);
}

.achievement-banner .box .content {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  opacity: 0;
  transition: opacity 0.3s;
}

.flip-container:hover .achievement-banner .box .content {
  opacity: 1;
}

.achievement-banner .box .content h5,
.achievement-banner .box .content p {
  margin: 0;
  text-align: center;

}

.box img {
  border-radius: 8px;
  width: 100%;
  border: 1px solid grey;
}





.content p {
  margin: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
}

/********** Achievement CSS Ends **********/

/********** Popular Courses CSS Starts **********/


.course-list i {
  margin-top: 3px;
  font-size: 24px;
}

.course-list-f i {
  margin-top: 3px;
  font-size: 24px;
}

.course-list p {
  font-size: 16px;
  line-height: 25px;
  font-weight: bold;
}

.course-list-f p {
  font-size: 16px;
  line-height: 25px;
  font-weight: bold;
}
.he {
    color:#2c3e50;
    font-size: 28px;

}
.sb-he{
  color: whitesmoke;
  font-size:1rem;

}


/********** Popular Courses CSS Ends **********/

/********** Teachers Section CSS Starts **********/
.our-teacher {
  padding: 20px;
  width: 100%;


}

.our-teacher p {
  font-size: 15px;
  line-height: 25px;
  margin-top: 10px;
}



/********** Teachers Section CSS Ends **********/



.circle img {

  height: 70px;
  width: 70px;
  border-radius: 50px;
  padding: 12px;
}

.circle h5 {
  margin-top: 2px;
  font-size: 14px;
}


a {
  text-decoration: none;
}



.achievement-banner .box .content h5,
.achievement-banner .box .content p {
  margin: 0;
  text-align: center;

}

.box img {
  border-radius: 8px;
  width: 100%;
  border: 1px solid grey;
}


.content p {
  margin: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
}

/********** Achievement CSS Ends **********/
/********** Popular Courses CSS Starts **********/


.course-list i {
  font-size: 24px;
}

.course-list p,
.exam-boards {
  font-size: 16px;
  margin-top: 10px;
  line-height: 25px;
  font-weight: bold;
}

.course-list img {
  border-radius: 8px;
  width: 70px;


}



.popular-course p ,.our-teacher p {
  font-size: 16px;
  margin-top: 10px;
  line-height: 25px;
  font-weight: 400;
}
.img img{
  width: 101%;
  border-radius: 5px;
}

/********** Popular Courses CSS Ends **********/
/********** Teachers Section CSS Starts **********/


.links {
  display: flex;
}

.links a {
  padding: 4px 12px;


  font-size: 10px;;
}


.card {
  background: #2c3e50;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  margin-bottom: 45px;


}

.card .card-img {
  width: 100%;
  height: 6rem;
  object-fit: cover;
  border-radius: 8px;

}

.profile-img {
  width: 7rem;
  height: 7rem;
  object-fit: cover;
  border-radius: 50%;
  margin-top: -8rem;
  z-index: 1;
  border: .2rem solid #333;
}

.card h1 {
  font-size: 1.5rem;
  color: #ccc;
  margin-top: 1.5rem;
  margin-bottom: 0;
}

.job-title {
  color: #aaa;
  font-size: .5rem;
  font-weight: 300;

}



@media screen and (max-width: 425px) {
  .subject{

    width: 20rem;
    height: 20rem;
  }
  .subject-box {
    width: 22.5rem;
    height: 16rem;
    padding: 10px;



  }



  .subject-box h2 {
    font-size: 1.5rem;

  }

  .subject-box p {
    font-size: 1rem;

  }

  .subject-box .subject-description p{

    font-size: .85rem;
    line-height: 1rem;

  }



  .main-section p {

    font-size: .85rem;
    line-height: 1;

  }

  .popups h5 {
    color: #2B6E60;
    font-weight: bold;
    font-size: 18px;
    margin-top: 24px;
  }



  .box img {
    border-radius: 8px;
    width: 100%;
    border: 1px solid grey;
  }





  .content p {
    margin: 0;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
  }

  /********** Achievement CSS Ends **********/

  /********** Popular Courses CSS Starts **********/


  .course-list i {
    margin-top: 3px;
    font-size: 24px;
  }

  .course-list-f i {
    margin-top: 3px;
    font-size: 24px;
  }

  .course-list p {
    font-size: 16px;
    line-height: 25px;
    font-weight: bold;
  }

  .course-list-f p {
    font-size: 16px;
    line-height: 25px;
    font-weight: bold;
  }
  .he {
    font-size:1.5rem;

  }
  .sb-he{
    color: whitesmoke;
    font-size:1rem;

  }



  /********** Popular Courses CSS Ends **********/

  /********** Teachers Section CSS Starts **********/
  .our-teacher {
    padding: 20px;
    width: 100%;


  }

  .our-teacher p {
    font-size: 15px;
    line-height: 25px;
    margin-top: 10px;
  }



  /********** Teachers Section CSS Ends **********/



  .circle img {

    height: 70px;
    width: 70px;
    border-radius: 50px;
    padding: 12px;
  }

  .circle h5 {
    margin-top: 2px;
    font-size: 14px;
  }


  a {
    text-decoration: none;
  }


  .flip-container:hover .flipper {
    transform: rotateY(180deg);
  }

  .achievement-banner .box .content {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .flip-container:hover .achievement-banner .box .content {
    opacity: 1;
  }

  .achievement-banner .box .content h5,
  .achievement-banner .box .content p {
    margin: 0;
    text-align: center;

  }

  .box img {
    border-radius: 8px;
    width: 100%;
    border: 1px solid grey;
  }


  .content p {
    margin: 0;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
  }

  /********** Achievement CSS Ends **********/
  /********** Popular Courses CSS Starts **********/


  .course-list i {
    font-size: 24px;
  }

  .course-list p,
  .exam-boards {
    font-size: 16px;
    margin-top: 10px;
    line-height: 25px;
    font-weight: bold;
  }

  .course-list img {
    border-radius: 8px;
    width: 70px;


  }

  .popular-course h2 {
    color: #064727;
    font-size: 24px;
  }

  .popular-course p ,.our-teacher p {
    font-size: .95rem;
    line-height: 1.5rem;

  }


  /********** Popular Courses CSS Ends **********/
  /********** Teachers Section CSS Starts **********/


  .links {
    display: flex;
    margin-bottom: 1rem;
  }

  .links a {
    padding: 4px 12px;


    font-size: 10px;;
  }


  .card {
    background: #2c3e50;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    margin-bottom: 45px;


  }

  .card .card-img {
    width: 100%;
    height: 6rem;
    object-fit: cover;
    border-radius: 8px;

  }

  .profile-img {
    width: 7rem;
    height: 7rem;
    object-fit: cover;
    border-radius: 50%;
    margin-top: -8rem;
    z-index: 1;
    border: .2rem solid #333;
  }

  .card h1 {
    font-size: 1.5rem;
    color: #ccc;
    margin-top: 1.5rem;
    margin-bottom: 0;
  }

  .job-title {
    color: #aaa;
    font-size: .5rem;
    font-weight: 300;

  }



}





</style>