<template>
  <div class="container-fluid">
    <div class="row d-flex justify-content-center align-content-center test">
      <div class="col-12 col-md-5 p-3">
        <div class="welcome-message">
          <h1 class="text-start">Enroll with us</h1>
          <p></p>
          <span>Welcome,to Our Online Registration Platform</span>
          <p>Southmews  has implemented an online enrollment platform to simplify the student registration process. The
            platform allows prospective and current students at Southmews to conveniently manage their academic records
            and enrollment details through a secure web-based interface. Our admin team will respond to you as soon as
            possible</p>
        </div>
      </div>
      <div class="col-12  col-md-7 p-3 ">
        <div class="card">
          <div class="card-body">
            <div class="registration-form">
              <form class="" @submit.prevent="submitForm()">
                <div class="row ">
                  <div class="col-md-12">


                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-label" for="First_Name">First Name:</label>
                          <input
                              type="text"
                              class="form-control"
                              id="First_Name"
                              name="First_Name"
                              maxlength="30"

                              v-model="firstName"
                          />
                          <div class=" showError">{{ firstNameError }}</div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-label" for="Last_Name">Last Name:</label>
                          <input
                              type="text"
                              class="form-control"
                              id="Last_Name"
                              name="Last_Name"
                              maxlength="30"

                              v-model="lastName"
                          />
                          <div class=" showError">{{ lastNameError }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-label" for="Date_of_Birth">Date of Birth:</label>
                          <input
                              type="date"
                              class="form-control"
                              id="Date_of_Birth"
                              name="Date_of_Birth"

                              v-model="dateOfBirth"
                          />
                          <div class=" showError">{{ dateOfBirthError }}</div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-label" for="Gender">Gender:</label>
                          <select class="form-control" id="Gender" name="Gender" v-model="gender">
                            <option value="" selected disabled>Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                          </select>
                          <div class=" showError">{{ genderError }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="form-label" for="Address">Address:</label>
                      <textarea class="form-control" id="Address" name="Address" rows="4" v-model="address"></textarea>
                      <div class=" showError">{{ addressError }}</div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-label" for="City">City:</label>
                          <input
                              type="text"
                              class="form-control"
                              id="City"
                              name="City"
                              maxlength="30"

                              v-model="city"
                          />
                          <div class=" showError">{{ cityError }}</div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-label" for="Country">Country:</label>
                          <select class="form-control" id="Country" name="Country" v-model="country">
                            <option value="">Select country</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                            <option value="International">International</option>
                          </select>
                          <div class=" showError">{{ countryError }}</div>
                        </div>
                      </div>
                    </div>

                  </div>


                </div>
                <div class="row ">
                  <div class="col-md-12">


                    <div class="row">
                      <div class="col-md-6">

                        <div class="form-group">
                          <label class="form-label" for="Email_Id">Email:</label>
                          <input
                              type="email"
                              class="form-control"
                              id="Email_Id"
                              name="Email_Id"

                              v-model="emailId"
                          />
                          <div class=" showError">{{ emailIdError }}</div>
                        </div>
                      </div>
                      <div class="col-md-6">

                        <div class="form-group">
                          <label class="form-label" for="Mobile_Number">Mobile Number:</label>
                          <input
                              type="text"
                              class="form-control"
                              id="Mobile_Number"
                              name="Mobile_Number"
                              maxlength="10"

                              v-model="mobileNumber"
                          />
                          <div class=" showError">{{ mobileNumberError }}</div>
                        </div>
                      </div>
                    </div>



                  </div>

                  <div class="form-group center-align mt-3 mb-1v">
                    <button class="btn btn-success" type="submit" >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>






</template>

<script>
export default {

  data() {
    return {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      gender: '',
      address: '',
      city: '',
      country: '',
      guardianFirstName: '',
      guardianLastName: '',
      guardianRelationship: '',
      guardianAddress: '',
      guardianCity: '',
      guardianCountry: '',
      emailId: '',
      mobileNumber: '',
      guardianEmailId: '',
      guardianMobileNumber: '',


      firstNameError: '',
      lastNameError: '',
      dateOfBirthError: '',
      genderError: '',
      addressError: '',
      cityError: '',
      countryError: '',
      guardianFirstNameError: '',
      guardianLastNameError: '',
      guardianRelationshipError: '',
      guardianAddressError: '',
      guardianCityError: '',
      guardianCountryError: '',
      emailIdError: '',
      mobileNumberError: '',
      guardianEmailIdError: '',
      guardianMobileNumberError: '',


    }
  },
  methods: {
    submitForm: function () {
      this.clearErrors();
      let isValid = true;

      // Personal Details
      if (this.firstName === '') {
        this.showError('firstName', 'Please enter your first name.');
        isValid = false;
      }

      if (this.lastName === '') {
        this.showError('lastName', 'Please enter your last name.');
        isValid = false;
      }

      if (this.dateOfBirth === '') {
        this.showError('dateOfBirth', 'Please enter your date of birth.');
        isValid = false;
      }

      if (this.gender === '') {
        this.showError('gender', 'Please select your gender.');
        isValid = false;
      }

      if (this.address === '') {
        this.showError('address', 'Please enter your address.');
        isValid = false;
      }

      if (this.city === '') {
        this.showError('city', 'Please enter your city.');
        isValid = false;
      }

      if (this.country === '') {
        this.showError('country', 'Please enter your country.');
        isValid = false;
      }

      // Guardian Details
      if (this.guardianFirstName === '') {
        this.showError('guardianFirstName', 'Please enter guardian\'s first name.');
        isValid = false;
      }

      if (this.guardianLastName === '') {
        this.showError('guardianLastName', 'Please enter guardian\'s last name.');
        isValid = false;
      }

      if (this.guardianRelationship === '') {
        this.showError('guardianRelationship', 'Please enter the relationship with the guardian.');
        isValid = false;
      }

      if (this.guardianAddress === '') {
        this.showError('guardianAddress', 'Please enter guardian\'s address.');
        isValid = false;
      }

      if (this.guardianCity === '') {
        this.showError('guardianCity', 'Please enter guardian\'s city.');
        isValid = false;
      }

      if (this.guardianCountry === '') {
        this.showError('guardianCountry', 'Please enter guardian\'s country.');
        isValid = false;
      }

      // Contact Details

      if (!this.isValidEmail(this.emailId)) {
        this.showError('emailId', 'Please enter a valid email address.');
        isValid = false;
      }

      if (this.mobileNumber === '') {
        this.showError('mobileNumber', 'Please enter your mobile number.');
        isValid = false;
      }


      if (!this.isValidEmail(this.guardianEmailId)) {
        this.showError('guardianEmailId', 'Please enter a valid email address.');
        isValid = false;
      }

      if (this.guardianMobileNumber === '') {
        this.showError('guardianMobileNumber', 'Please enter guardian\'s mobile number.');
        isValid = false;
      }


      if (isValid) {
        // Perform any necessary actions here
        // For example, redirecting to a new page
        this.$router.push('/admissionFormCont');

      }
    },
    showError(field, message) {
      this[`${field}Error`] = message;
    },
    clearErrors() {
      this.firstNameError = '';
      this.lastNameError = '';
      this.dateOfBirthError = '';
      this.genderError = '';
      this.addressError = '';
      this.cityError = '';
      this.countryError = '';
      this.guardianFirstNameError = '';
      this.guardianLastNameError = '';
      this.guardianRelationshipError = '';
      this.guardianAddressError = '';
      this.guardianCityError = '';
      this.guardianCountryError = '';
      this.emailIdError = '';
      this.mobileNumberError = '';
      this.guardianEmailIdError = '';
      this.guardianMobileNumberError = '';

    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }
  }

};
</script>

<style scoped>



.welcome-message {
  background-color: #090051;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  color: #FFffff;
}
.welcome-message h1{
  font-size: 32px;
  color: goldenrod;margin-top: 12px;
}
.welcome-message p{
  font-size: 16px;
  text-align: center;
  margin-top: 12px;
}
.welcome-message span{
  font-size: 32px;
  margin-top: 12px;
}


.registration-form h2 {
  color: goldenrod;

}

h3 {
  color: rgb(0, 0, 0);
}

img {
  height: 95px;
}

label, option, legend {
  font-size: 16px;
  font-weight: bold;
  color: rgb(0, 0, 0);
}

fieldset {
  margin-bottom: 12px;
}

.showError {
  color: crimson;
  margin-top: 5px;
  font-size: 14px;
}
@media screen and (max-width: 425px){

  .welcome-message {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  }
  .welcome-message h1{
    font-size: 1.5rem;

  }
  .welcome-message p{
    font-size: .85rem;
    margin-top: 10px;
    text-align: start;
  }
  .welcome-message span{
    font-size: 1.2rem;
    margin-top: 10px;
  }
  label, option, legend {
    font-size: .85rem;
  }
  fieldset {
    margin-bottom: 10px;
  }

  .showError {
    color: crimson;
    margin-top: 5px;
    font-size: .85rem;
  }


}
</style>