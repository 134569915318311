<template>
  <div class="container privacypolicy">
    <div class="row center-align mt-3">
      <div class="col-md-12 w-75">
        <h1>Privacy and Policies</h1>
        <h4>INTERGRITY</h4>
        <p >
          This page informs you of our policies regarding the collection, use
          and disclosure of Personal Information when you use our Service. We
          will not use or share your information with anyone except as described
          in this Privacy Policy. We use your Personal Information for providing
          and improving the Service.
        </p>

        <h4>AGREEMENT TO TERMS</h4>
        <p>
          By using the Service, you agree to the collection and use of
          information in accordance with this policy. Unless otherwise defined
          in this Privacy Policy, terms used in this Privacy Policy have the
          same meanings as in our Terms and Conditions, accessible at
          www.southmewshigh.com/terms-conditions.
        </p>

        <h4>INFORMATION COLLECTION AND USE</h4>
        <p>
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you. Personally identifiable information (“Personal
          Information”) may include, but is not limited to: Name Email address
          Telephone number LOG DATA We collect information that your browser
          sends whenever you visit our Service (“Log Data”). This Log Data may
          include information such as your computers Internet Protocol (“IP”)
          address, browser type, browser version, the pages of our Service that
          you visit, the time and date of your visit, the time spent on those
          pages and other statistics. COOKIES Cookies are files with small
          amount of data, which may include an anonymous unique identifier.
          Cookies are sent to your browser from a web site and stored on your
          computers hard drive. We use “cookies” to collect information. You can
          instruct your browser to refuse all cookies or to indicate when a
          cookie is being sent. However, if you do not accept cookies, you may
          not be able to use some portions of our Service.
        </p>
        <h4>SERVICE PROVIDERS</h4>
        <p>
          We may employ third party companies and individuals to facilitate our
          Service, to provide the Service on our behalf, to perform
          Service-related services or to assist us in analyzing how our Service
          is used. These third parties have access to your Personal Information
          only to perform these tasks on our behalf and are obligated not to
          disclose or use it for any other purpose.
        </p>
        <h4>SECURITY</h4>
        <p>
          The security of your Personal Information is important to us, but
          remember that no method of transmission over the Internet, or method
          of electronic storage is 100% secure. While we strive to use
          commercially acceptable means to protect your Personal Information, we
          cannot guarantee its absolute security.
        </p>
        <h4>LINKS TO OTHER SITES</h4>
        <p>
          Our Service may contain links to other sites that are not operated by
          us. If you click on a third party link, you will be directed to that
          third partys site. We strongly advise you to review the Privacy Policy
          of every site you visit. We have no control over, and assume no
          responsibility for the content, privacy policies or practices of any
          third party sites or services. CHILDRENS PRIVACY Our Service does not
          address anyone under the age of 18 (“Children”). We do not knowingly
          collect personally identifiable information from children under 18. If
          you are a parent or guardian and you are aware that your child has
          provided us with Personal Information, please contact us. If we
          discover that a child under 18 has provided us with Personal
          Information, we will delete such information from our servers
          immediately. COMPLIANCE WITH LAWS We will disclose your Personal
          Information where required to do so by law or subpoena.
        </p>
        <h4>CHANGES TO THIS PRIVACY POLICY</h4>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page. You are
          advised to review this Privacy Policy periodically for any changes.
          Changes to this Privacy Policy are effective when they are posted on
          this page. CONTACT US If you have any questions about this Privacy
          Policy, please contact us.
        </p>
      </div>
      <div class="col-md-12 w-75">
        <h1>LICENCE</h1>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Provident
          laborum omnis quam inventore illo. Molestiae porro magni magnam.
          Sapiente, molestiae?
        </p>
        <img src="../assets/images/Southmews High Logo 1.jpg" alt="licence">
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.privacypolicy {
  font-family: "Times New Roman", Times, serif;
}
P {
  font-size: 16px;
}
img{
  width:90px;
}
</style>