<template>

  <div class="row ">
    <div class="col-md-12">
      <div class="carousel">
        <div class="list ">
          <div v-for="slide in slides" :key="slide" class="item justify-content-center align-content-center d-flex">
            <img class="" :alt="slide.alt" :src="slide.image" >
            <div class="content modal-mask">
              <div class="hero-content">
                <h1>Elevate Your Potential</h1>
                <h2>Embark on an Exceptional Journey at SouthMews Collage</h2>
                <p>SouthMews Collage offers a transformative educational experience that empowers you to unlock
                  your true potential. Immerse yourself in a vibrant community that nurtures academic excellence,
                  fosters personal growth, and equips you with the skills to thrive in your future.</p>
                <p>Take the first step towards a brighter tomorrow. Discover the limitless possibilities that await
                  you at SouthMews Collage</p>
              </div>
              <div class="button ">
                <p class="links">
                  <router-link to="/AdmissionForm">
                    APPLY NOW
                  </router-link>
                </p>
              </div>
            </div>
          </div>

        </div>
        <div class="arrows ">
          <button id="prev"><</button>
          <button id="next">></button>
        </div>
      </div>
    </div>
</div>







</template>
<script>
export default {
  name: "BannerSlider",
  data() {
    return {
      slides: [
        {
          image: require('@/assets/images/school2.jpg'),
          alt: 'school2'
        },
        {
          image: require('@/assets/images/school1.jpg'),
          alt: 'image2'
        },
        {
          image: require('@/assets/images/IMG-20241009-WA0067.jpg'),
          alt: 'image3'
        },
        {
          image: require('@/assets/images/students.jpg'),

          alt: 'image4'
        },
        {
          image: require('@/assets/images/corridor.jpg'),
          alt: 'image5'
        }
      ],
    }
  },
  methods: {
    startSlider() {

      let nextDom = document.getElementById('next');
      let prevDom = document.getElementById('prev');

      let carouselDom = document.querySelector('.carousel');
      let SliderDom = carouselDom.querySelector('.carousel .list');

      let timeRunning = 3000;
      let timeAutoNext = 7000;

      nextDom.onclick = function () {
        showSlider('next');
      }

      prevDom.onclick = function () {
        showSlider('prev');
      }
      let runTimeOut;
      let runNextAuto = setTimeout(() => {
        nextDom.click();
      }, timeAutoNext)

      function showSlider(type) {
        let SliderItemsDom = SliderDom.querySelectorAll('.carousel .list .item');
        if (type === 'next') {
          SliderDom.appendChild(SliderItemsDom[0]);
          carouselDom.classList.add('next');
        } else {
          SliderDom.prepend(SliderItemsDom[SliderItemsDom.length - 1]);
          carouselDom.classList.add('prev');
        }
        clearTimeout(runTimeOut);
        runTimeOut = setTimeout(() => {
          carouselDom.classList.remove('next');
          carouselDom.classList.remove('prev');
        }, timeRunning);

        clearTimeout(runNextAuto);
        runNextAuto = setTimeout(() => {
          nextDom.click();
        }, timeAutoNext)
      }
    }
  },
  mounted() {
    this.startSlider()
  }

}
</script>

<style scoped>
.carousel {
  height: 80vh;
  width:100%;
  border-radius: 6px;
  overflow: hidden;

}

.carousel .list .item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease-in-out;
}

.carousel .list .item img {
  width: 100%;
  height: 100%;

}

.modal-mask {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  height: 80vh;
  width: 100%;
  background-color: rgba(0, 0, 0, .20);
  display: table;
  transition: opacity .3s ease;
}

.carousel .list .item .content {
  padding: 30px;
  position: absolute;
  height: 80vh;
  width:100%;
  left: 50%;
  transform: translateX(-50%);
  color: #f0f0f0;
}

.hero-content {

  max-width: 600px;
  text-align: left;
}

.hero-content h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;

}

.hero-content h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;

}

.hero-content p {
  font-size: 16px;
  line-height: 2;
  margin-bottom: 1rem;
}





.carousel .list .item:nth-child(1) {
  z-index: 1;
}






@keyframes showContent {
  to {
    transform: translateY(0px);
    filter: blur(0px);
    opacity: 1;
  }
}



.carousel.next .list .item:nth-child(1) img {
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 50%;
  border-radius: 10px;
  animation: showImage 1.5s linear 1 forwards;
}

@keyframes showImage {
  to {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}


.carousel.prev .list .item img {
  z-index: 100;
}


@keyframes effectNext {
  from {
    transform: translateX(150px);
  }
}

.carousel.prev .list .item:nth-child(2) {
  z-index: 2;
}

.carousel.prev .list .item:nth-child(2) img {
  animation: outFrame 10.5s linear 1 forwards;
  position: absolute;
  bottom: 0;
  left: 0;
}

@keyframes outFrame {
  to {
    width: 150px;
    height: 220px;
    bottom: 50px;
    left: 50%;
    border-radius: 20px;
  }
}


@keyframes contentOut {
  to {
    transform: translateY(-150px);
    filter: blur(20px);
    opacity: 0;
  }
}

@media screen and (max-width: 1024px) {

  .carousel {
    width: 98vw;
  }

  .carousel .list .item .content {
    width: 9140px;
    max-width: 100%;


  }





}

@media screen and (max-width: 768px) {
  .carousel {
    width: 97vw;
  }

}

@media screen and (max-width: 425px) {
  .carousel {
    width: 92vw;
    height:50vh;
  }

  .hero-content {
    max-width: 210px;

  }

  .hero-content h1 {
    font-size: 2rem;


  }

  .hero-content h2 {
    font-size: 1.2rem;


  }

  .hero-content p {
    font-size: 1rem;
    line-height: 1.2;
    margin-bottom: 1rem;
  }


  .carousel .list .item .buttons {
    grid-template-columns: repeat(2, 90px);
    grid-template-rows: 30px;

  }

  .carousel .list .item .buttons button a {

    font-size: 14px;


  }

  .arrows {

    font-weight: bold;
    font-size: 24px;
    right: 28%;
  }


  .arrows button {
    width: 35px;
    height: 35px;

  }
}
@media screen and (max-width: 430px){
  .carousel {
    height: 55vh;
    width:101%;
  }

  .carousel .list .item .content {
    padding: 10px;
  }

  .hero-content {
    max-width: 300px;

  }

  .hero-content h1 {
    font-size: 1.5rem;
  }

  .hero-content h2 {
    font-size: 1rem;


  }

  .hero-content p {
    font-size: .85rem;
    line-height: 1.5;
    margin-bottom: 1rem;
  }





  .carousel .list .item:nth-child(1) {
    z-index: 1;
  }






  @keyframes showContent {
    to {
      transform: translateY(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }



  .carousel.next .list .item:nth-child(1) img {
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 0;
    left: 50%;
    border-radius: 10px;
    animation: showImage 1.5s linear 1 forwards;
  }

  @keyframes showImage {
    to {
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 0;
    }
  }


  .carousel.prev .list .item img {
    z-index: 100;
  }


  @keyframes effectNext {
    from {
      transform: translateX(150px);
    }
  }

  .carousel.prev .list .item:nth-child(2) {
    z-index: 2;
  }

  .carousel.prev .list .item:nth-child(2) img {
    animation: outFrame 10.5s linear 1 forwards;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  @keyframes outFrame {
    to {
      width: 150px;
      height: 220px;
      bottom: 50px;
      left: 50%;
      border-radius: 20px;
    }
  }


  @keyframes contentOut {
    to {
      transform: translateY(-150px);
      filter: blur(20px);
      opacity: 0;
    }
  }
}





</style>