<template>

    <div class="row" >
      <div class="col-6 col-md-12 ">
        <nav class="navbar navbar-expand-md navbar-dark  bsb-navbar-hover
         bsb-navbar-caret fixed-top nav-underline row d-flex ">
          <div class="col-3 col-md-3">
            <router-link class="navbar-brand  " to="/">
              <img class="logo " src="../assets/images/logo.jpg" alt=" Logo"  height="50px" width="50px"  >
            </router-link>
          </div>
          <div class="col-3 col-md-9">
            <button class="navbar-toggler me-2 " type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
              </svg>
            </button>
            <div class="offcanvas offcanvas-start w-50 h-75 rounded " tabindex="-3" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
              <div class="offcanvas-header bg-dark text-bg-secondary ">
                <h5 class="offcanvas-title  " id="offcanvasNavbarLabel">Menu</h5>
                <button type="button" class="btn-close btn-sm text-bg-secondary " data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <div class="offcanvas-body d-flex flex-column">

                <ul class="navbar-nav justify-content-evenly w-100 ">
                  <li class="nav-item">
                    <router-link class="nav-link  dropdown" aria-current="page" to="/">Home</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link  dropdown" aria-current="page" to="/AdmissionForm">Admission</router-link>
                  </li>

                  <li class="nav-item dropdown">
                    <router-link class="nav-link " to="#!" id="accountDropdown"  data-bs-toggle="dropdown" aria-expanded="false">  Payments</router-link>
                    <ul class="dropdown-menu dropdown-content  border-0 shadow bsb-zoomIn" aria-labelledby="accountDropdown">

                      <li><router-link class="dropdown-item" to="/payments">Make Payment</router-link></li>
                      <li><router-link class="dropdown-item" to="feesStructure">School Fees Structure</router-link></li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link  dropdown" aria-current="page" to="/SubjectsAndCourses">Subjects</router-link>
                  </li>
                  <li class="nav-item dropdown">
                    <router-link class="nav-link " to="#!" id="accountDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> Resources</router-link>
                    <ul class="dropdown-menu dropdown-content  border-0 shadow bsb-zoomIn" aria-labelledby="accountDropdown">
                      <!--                    <li><router-link class="dropdown-item" to="/PrivacyPolicy">Privacy Policy & Licenses</router-link></li>-->
                      <li><router-link class="dropdown-item" to="/howToApply">How To Apply</router-link></li>
                      <!--                    <li><router-link class="dropdown-item" to="#">Student Info & Forms</router-link></li>-->
                      <!--                    <li><router-link class="dropdown-item" to="#">Records Request</router-link></li>-->
                      <li><router-link class="dropdown-item" to="/Parents">Parents</router-link></li>
                    </ul>
                  </li>
                  <li class="nav-item dropdown">
                    <router-link class="nav-link " to="/about" >About US</router-link>
                    <!--                  <ul class="dropdown-menu dropdown-content  border-0 shadow bsb-zoomIn" aria-labelledby="servicesDropdown">-->
                    <!--                    <li><router-link class="dropdown-item" to="/about">At a Glance</router-link></li>-->
                    <!--                    <li><router-link class="dropdown-item" to="/History">History</router-link></li>-->
                    <!--                    <li><router-link class="dropdown-item" to="/Teachers">Stuff Team</router-link></li>-->
                    <!--                  </ul>-->
                  </li>
                  <!--                <li class="nav-item dropdown">-->
                  <!--                  <router-link class="nav-link" to="/AllEvents">Events</router-link>-->
                  <!--                </li>-->
                  <li class="nav-item dropdown">
                    <router-link class="nav-link" to="/ContactUs">Contact us</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>



        </nav>
      </div>



    </div>



</template>

<script>
export default {

  data() {
    return {
      currentDate: "",
    };
  },

};
</script>

<style scoped >
.navbar{
  background-color: #090051;
  border-bottom:1px solid white;

}
.offcanvas-body{
  background-color: #090051;

}
.logo {
  border-radius: 50%;
  margin-left: 20px;
  margin-right: 20px;
}
.nav-link{
  display: flex;
  justify-items: center;
  text-transform: uppercase;
}
.nav-item a{
  color: white;
  margin-top: 0;
}
.dropdown {
  font-size: 16px;
}
.dropdown-content {
  min-width: 165px;
  background: rgb(143, 173, 202);
  font-size: 14px;
  border-top: 2px solid rgb(74, 74, 74);
  border-bottom: 2px solid rgb(99, 146, 190);
  box-shadow: 0 8px 16px 0 rgba(59, 148, 196, 0.4);

}
.dropdown-content a {
  color: black;
  padding: 0 4px 0 8px;
  text-decoration: none;
  text-align: left;
  margin-bottom: 8px;
  text-transform: capitalize;
  background: none;
}

.dropdown-content a:hover {
  color: rgb(32, 32, 32);
  font-weight: bold;
}
.navbar,.offcanvas-body{
  background-color: #090051;
}
@media  screen and (max-width: 425px){
  .logo {
    margin-left: 20px;
    margin-right: 20px;
  }

}
</style>
