<template>
  <div class="container-fluid mt-4">

    <div class="about  mb-5">
      <div class="container  text-center about-us col-sm-10">
        <div class="row">
          <h1 class="display-6">About Us</h1>
          <h4 class="mb-5">SouthMews<br><br></h4>
          <p class="text-start mt-5" style="font-size: large;">
            Our school, though relatively young, has quickly become a vibrant learning community where students,
            parents, and staff feel a deep sense of belonging. Founded on the principles of inclusivity, community, and
            academic excellence, our school's vision is to create an environment where every student feels valued,
            supported, and inspired to reach their full potential. Our dedicated team of educators shares this mission,
            fostering a culture of belonging through community-building activities and innovative teaching methods.

            <br> <br>
            Through rigorous and relevant education, we prepare students for the challenges and opportunities of the
            21st century. Our school offers diverse extracurricular activities, catering to various interests and
            talents, to promote holistic development. As we continue to grow, we remain committed to our vision of
            providing an exceptional educational experience. By nurturing a culture of belonging, innovation, and
            excellence, we empower students to thrive academically, socially, and emotionally, preparing them for a
            successful future.
          </p>
        </div>
      </div>
    </div>



  </div>

</template>

<script>
export default {
  name: 'AboutUs',
}
</script>

<style scoped>

.about-us {

  margin-bottom: 2rem;
  background-color: rgba(201, 201, 201, 0.85); /* Creamy color with 92.5% opacity */
 transition: all 1s ease-in-out 0s;
}




div {
  transition: all 1s ease-in-out 0s;
}

@media (min-width: 0px) and (max-width: 992px) {
  div.about-us {
    margin-top: -1rem;
  }

  #background-div {
    display: none;
    margin-top: 0;
  }
}

@media (min-width: 0px) and (max-width: 500px) {
}
</style>

